import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { FaAngleDown } from "react-icons/fa"
import { useLocation } from "@reach/router"
import CoqIcon from "../../../../static/Coq.svg"

import {
  ProductTitleWrapper,
  ProductSingleTitle,
  ProductInfoSection,
  Container,
  ProductTopDesktopSection,
  ProductInfoBox,
  ProductImageSection,
  ProductOverlay,
  ProductMobileBelowImageSection,
  ProductRightInfoSection,
  ProductSummaryTop,
  ProductSummaryBottom,
  ProductBottomInfo,
  DesktopProductBottomPriceInfo,
  MobileProductBottomPriceInfo,
  ProductBottomCartButtonBox,
  ProductInfoBottom,
  DesktopPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  GuideSizeDetail,
  ProductAlmaDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  Select,
  LayerDisabled,
  UserTypeWrapper,
  ChooseGenderText,
  ErrorBox,
  DropIcon,
  DropIconMobile,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  ChooseSizeSeggestionText,
  ProductStrikePrice,
  ProductDiscountPrice,
  LyneupContainer,
  LyneProductStrikePrice,
  LyneProductDiscountPrice,
  LyneComboSection,
  LyneComboWrapper,
  LyneComboBox,
  LyneComboBoxTagWrapper,
  LyneComboInfoIcon,
  LyneComboBoxTag,
  LyneComboBoxTitle,
  LyneComboBottomSection,
  LyneComboLeftSection,
  LyneComboBoxImage,
  LyneCheckImage,
  LyneComboRightBox,
  DisableLayer,
  ProductOutOfStockMessage,
  ClockImage,
  LyneProductRegularPrice,
  SizeRulerBox,
  SizeRulerImage,
} from "../../../components/Products/styled"

import ProductTopSection from "../../../components/Products/ProductTopSection"
import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductMaterialInfo from "../../../components/Products/ProductMaterialInfo"
import ProductUserType from "../../../components/Products/ProductUserType"
import ProductColorBox from "../../../components/Products/ProductColorBox"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductInfoBanner from "../../../components/Products/ProductInfoBanner"
import ProductResultSection from "../../../components/Products/ProductResultSection"
import ProductWhyChooseSection from "../../../components/Products/ProductWhyChooseSection"
import ProductExpertSection from "../../../components/Products/ProductExpertSection"
import ProductSatisfiedClientSection from "../../../components/Products/ProductSatisfiedClientSection"
import ProductVideoSection from "../../../components/Products/ProductVideoSection"
import ProductReviewSection from "../../../components/Products/ProductReviewSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import CustomerService from "../../../components/Layout/CustomerService"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import PriceDetails from "../../../components/Products/PriceDetails"
import SizeGuidePopup from "../../../components/Products/SizeGuidePopup"
import {
  CapitalizeFirstLetter,
  colorsLyneFit,
  imagesLyneFit,
  gendersLyneFit,
  imageSliderLyneFit,
  colorsReverseLyneFit,
  genderReverseLyneFit,
} from "../../../utils/lyneFitHelper"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import MedicaleInfoSection from "../../../components/Layout/MedicaleInfoSection"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import ProductBlueBanner from "../../../components/Products/ProductBlueBanner"
import {
  getPrice,
  monthlyPrice,
  navigateToCart,
} from "../../../utils/navigateToCart"
import ProductKlarnaSection from "../../../components/Products/ProductKlarnaSection"
import ProductPriceInformationSection from "../../../components/Products/ProductPriceInformationSection"
import TestimonialSection from "../../../components/Layout/TestimonialSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import LyneupHighlightSection from "../../../components/Products/LyneupHighlightSection"
import LyneupProductInformationSection from "../../../components/Products/LyneupProductInformationSection"
import LyneupTechnologySection from "../../../components/Products/LyneupTechnologySection"
import LyneupUseSection from "../../../components/Products/LyneupUseSection"
import LyneupMedicalSection from "../../../components/Products/LyneupMedicalSection"
import LyneupReviewSection from "../../../components/Products/LyneupReviewSection"
import LyneHomeCrossProductSection from "../../../components/Products/LyneHomeCrossProductSection"
import HowToUseSection from "../../../components/Products/HowToUseSection"
import LyneupBProductTopSection from "../../../components/Products/LyneupBProductTopSection"
import LyneFitFaqSection from "../../../components/Products/LyneFitFaqSection"
import ReactTooltip from "react-tooltip/dist"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import ResultHomeSection from "../../../components/Layout/ResultHomeSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"
import { OutOfStockItems } from "../../../utils/lyneUphelpers"

const LyneFit = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lynefit"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({
    Gender: process.env.GATSBY_CURRENCY_TYPE === "gbp" ? "Female" : "",
    Color:
      process.env.GATSBY_CURRENCY_TYPE === "gbp"
        ? "BLACK MICRO-PERFORATED"
        : "",
    Size: "",
  })
  const [openModal, setopenModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [openSizeGuideModal, setopenSizeGuideModal] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [activeFlag, setactiveFlag] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [discountPrice, setdiscountPrice] = useState(
    process.env.GATSBY_CURRENCY_TYPE === "gbp"
      ? 84
      : process.env.GATSBY_CURRENCY_TYPE === "eur"
      ? 99
      : ""
  )
  const [selectProductType, setselectProductType] = useState("Seul")
  const [currentlyNotInStock, setcurrentlyNotInStock] = useState(false)

  const handleQuantityChange = event => {
    if (event.target.value > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
    setselectedVariantQuantity(event.target.value)
    productGtmEvent(product, selectedVariant, event.target.value, intl.locale)
  }

  const handleParamStrings = (selectedOptions, productType) => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    if (productType) {
      paramString = paramString + "&Offer" + "=" + productType
    }

    // navigate(`${currUrl}${paramString}`)
    isBrowser && history.replaceState(null, null, paramString)
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    let selectedOptions = { ...selectOptions }

    if (target.name === "Color") {
      selectedOptions[target.name] = colorsLyneFit[intl.locale][target.value]
    } else if (target.name === "Gender") {
      selectedOptions[target.name] = gendersLyneFit[intl.locale][target.value]
      if (!selectOptions["Color"]) {
        // selectedOptions["Color"] = "BLUE"
        selectedOptions["Color"] = "BLACK MICRO-PERFORATED"
      }
    } else {
      if (target.value) selectedOptions[target.name] = target.value
    }
    removeErros(selectedOptions)
    handleParamStrings(selectedOptions, selectProductType)
    setselectOptions(selectedOptions)

    if (Object.keys(selectedOptions).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      if (
        selectedOptions["Gender"] === "Female" &&
        selectedOptions["Color"] === "BLUE"
      ) {
        const getAllVariants =
          selectedVariant &&
          client.product.fetch(product.id).then(product => {
            let variant_id = selectedVariant.id
            let getMatchVariant = product.variants.find(function(variant) {
              return variant.id == variant_id
            })
            setcurrentlyNotInStock(getMatchVariant.currentlyNotInStock)
          })
      } else {
        setcurrentlyNotInStock(false)
      }
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
      setselectedVariant(selectedVariant)
    }
    // setselectedVariantImage(
    //   images[selectedOptions["Gender"]][selectedOptions["Color"]]
    // )
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element ===
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    const productType = urlParams.get("Offer")
    let options = { ...selectOptions }
    if (genderParam) {
      options.Gender = genderParam
      // options.Color = "BLUE"
      options.Color = "BLACK MICRO-PERFORATED"
    }

    if (colorParam) {
      options.Color = colorParam
    }
    //   else {
    //     options.Color = "BLACK MICRO-PERFORATED"
    //   }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      if (genderParam === "Female" && colorParam === "BLUE") {
        const getAllVariants =
          selectedVariant &&
          client.product.fetch(product.id).then(product => {
            let variant_id = selectedVariant.id
            let getMatchVariant = product.variants.find(function(variant) {
              return variant.id == variant_id
            })
            setcurrentlyNotInStock(getMatchVariant.currentlyNotInStock)
          })
      } else {
        setcurrentlyNotInStock(false)
      }
      setselectedVariant(selectedVariant)
    }

    if (productType) {
      if (productType === "Pack") {
        setselectedVariantQuantity(2)
      } else {
        setselectedVariantQuantity(1)
      }
      setselectProductType(productType)
    }

    setselectOptions(options)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const getDiscountPrice = event => {
    if (selectedVariantQuantity > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const handleProductType = productType => {
    let price = ""
    if (productType === "Pack") {
      price = lyneGroupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = lyneDiscountPrice()
      setselectedVariantQuantity(1)
    }

    let selectedOptions = { ...selectOptions }
    handleParamStrings(selectedOptions, productType)

    // setproductPrice(price)

    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
    setselectProductType(productType)
  }

  let discountPercentage = 0.2

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // lyneDiscountPrice = Number(lyneDiscountPrice) - 15
    } else {
      // lyneDiscountPrice =
      //   Number(lyneDiscountPrice) - Number(lyneDiscountPrice) * Number(discountPercentage)
    }

    return getPrice(lyneDiscountPrice)
  }

  const lyneGroupPrice = () => {
    let lyneGroupPrice = selectedVariant
      ? selectedVariant.price
      : product.priceRangeV2.maxVariantPrice.amount

    lyneGroupPrice = Number(lyneGroupPrice) * 2

    return lyneGroupPrice
  }

  const lyneGroupDiscountPrice = () => {
    let LyneGroupDiscountPrice = lyneGroupPrice()

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      discountPercentage = 0.125
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 50
    } else {
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 60
    }

    return getPrice(LyneGroupDiscountPrice)
  }

  var searchLocation = useLocation()
  useEffect(() => {
    BottomBarActive()
    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
  }, [])

  useEffect(() => {
    getDiscountPrice()
  }, [selectedVariantQuantity])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  return (
    data &&
    data.allDirectusLynefitTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="lynefit"
          hreflangPathname={{
            fr:
              process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr"
                ? "shop/solutions-textiles"
                : "shop/lynefit",
            en: `shop/lynefit`,
            de: `shop/lynefit`,
            es: `shop/lynefit`,
          }}
          noindex={
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            true
          }
          nofollow={
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            true
          }
        />

        <BottomStickyAddToCart
          title={data.allDirectusLynefitTranslation.nodes[0].product_title}
          image={
            data.allDirectusLynefitTranslation.nodes[0].product_title_logo_image
          }
          gender={genderReverseLyneFit[intl.locale][selectOptions["Gender"]]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({ id: "button_text_sticky_banner_no_size" })
              : data.allDirectusLynefitTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          regularPrice={Number(
            selectedVariant
              ? selectedVariant.price
              : product.priceRangeV2.maxVariantPrice.amount
          ).toFixed(0)}
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          page="lyneup"
          id="sticy-button"
          quantity={selectedVariantQuantity}
          discountPrice={
            selectedVariantQuantity == 1
              ? lyneDiscountPrice()
              : lyneGroupDiscountPrice()
          }
          disabled={
            process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
            selectOptions["Gender"] &&
            selectOptions["Color"] &&
            selectOptions["Size"] &&
            OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE]["lynefit"][
              selectOptions["Gender"]
            ][selectOptions["Color"]]?.includes(selectOptions["Size"])
              ? true
              : currentlyNotInStock
              ? true
              : selectOptions["Gender"]
              ? false
              : true
          }
        />

        <PriceDetails
          details={data.allDirectusLynefitTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />
        <PriceDetails
          details={data.allDirectusLynefitTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <SizeGuidePopup
          sizeChart={data.allDirectusLynefitTranslation.nodes[0]}
          gender={selectOptions["Gender"]}
          page="lynefit"
          sizeText={data.allDirectusLynefitTranslation.nodes[0].size_chart_text}
          open={openSizeGuideModal}
          closeHandle={setopenSizeGuideModal}
        />

        {data.allDirectusLynefitTranslation.nodes[0].product_title && (
          <ProductTitleWrapper>
            <ProductSingleTitle
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusLynefitTranslation.nodes[0].product_title,
              }}
            />
          </ProductTitleWrapper>
        )}

        <ProductTopDesktopSection>
          <Container>
            <ProductTopSection
              image={
                data.allDirectusLynefitTranslation.nodes[0]
                  .product_title_logo_image
              }
              review_data={data.allDirectusReviewTranslation.nodes}
              material_info={
                data.allDirectusLynefitTranslation.nodes[0]
                  .product_material_info
              }
              price={Number(
                selectedVariant
                  ? selectedVariant.price
                  : product.priceRangeV2.maxVariantPrice.amount
              ).toFixed(0)}
            >
              <UserTypeWrapper tablet={true}>
                {data.allDirectusLynefitTranslation.nodes[0]
                  .choose_gender_text && (
                  <ChooseGenderText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLynefitTranslation.nodes[0]
                          .choose_gender_text,
                    }}
                  />
                )}
                <ProductUserType
                  data={data.allDirectusLynefitTranslation.nodes[0].gender.item}
                  handleChange={handleChangeSelectOptions}
                  value={selectOptions["Gender"]}
                  product={"Lyne FIT"}
                />
              </UserTypeWrapper>
            </ProductTopSection>
          </Container>
        </ProductTopDesktopSection>

        <ProductInfoSection id="info-section">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                {!(selectOptions["Gender"] && selectOptions["Color"]) && (
                  <ProductImageSlider
                    data={imageSliderLyneFit[intl.locale]["Default"]}
                    medicaleLogo={
                      data.allDirectusCommonTranslation.nodes[0]
                        .medicale_logo &&
                      data.allDirectusCommonTranslation.nodes[0].medicale_logo
                        .data.url
                        ? data.allDirectusCommonTranslation.nodes[0]
                            .medicale_logo.data.url
                        : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                    }
                  />
                )}

                {selectOptions["Gender"] && selectOptions["Color"] && (
                  <ProductImageSlider
                    data={
                      selectOptions["Gender"] && selectOptions["Color"]
                        ? imageSliderLyneFit[intl.locale][
                            selectOptions["Gender"]
                          ][selectOptions[["Color"]]]
                        : data.allDirectusLynefitTranslation.nodes[0]
                            .product_images_section.men.noir
                    }
                    medicaleLogo={
                      data.allDirectusCommonTranslation.nodes[0]
                        .medicale_logo &&
                      data.allDirectusCommonTranslation.nodes[0].medicale_logo
                        .data.url
                        ? data.allDirectusCommonTranslation.nodes[0]
                            .medicale_logo.data.url
                        : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                    }
                  />
                )}
              </ProductImageSection>

              <ProductMobileBelowImageSection id="mobile_below_image">
                <ProductTopSection
                  image={
                    data.allDirectusLynefitTranslation.nodes[0]
                      .product_title_logo_image
                  }
                  price={Number(
                    selectedVariant
                      ? selectedVariant.price
                      : product.priceRangeV2.maxVariantPrice.amount
                  ).toFixed(0)}
                  review_data={data.allDirectusReviewTranslation.nodes}
                  material_info={
                    data.allDirectusLynefitTranslation.nodes[0]
                      .product_material_info
                  }
                  openModal={setopenModal}
                  alma={
                    process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" &&
                    true
                  }
                  klarna={process.env.GATSBY_CURRENCY_TYPE === "gbp" && true}
                ></ProductTopSection>
              </ProductMobileBelowImageSection>

              <ProductRightInfoSection>
                {/*<ProductSummaryTop> */}
                <ProductMaterialInfo
                  data={
                    data.allDirectusLynefitTranslation.nodes[0]
                      .product_material_info
                  }
                />
                {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                  intl.locale === "fr" && (
                    <MobileProductBottomPriceInfo>
                      <ProductPriceWrapper align="flex-start">
                        <ProductPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductPrice>
                        {/* <ProductDiscountPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${discountPrice}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductDiscountPrice>
                        <ProductStrikePrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductStrikePrice> */}
                        {/* <ProductPlusDetail
                          onClick={() => setopenModal(!openAlmaModal)}
                        >
                          {intl.formatMessage({
                            id: "product_plus_details_text",
                          })}
                        </ProductPlusDetail> */}
                      </ProductPriceWrapper>
                      <ProductAlmaPriceWrapper>
                        <ProductAlmaPrice>
                          ou 3 x{" "}
                          <span>
                            {monthlyPrice(
                              discountPrice
                                ? 129
                                : selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            )}
                          </span>
                        </ProductAlmaPrice>
                        <ProductAlmaDetail
                          onClick={() => setopenAlmaModal(!openAlmaModal)}
                        >
                          {intl.formatMessage({
                            id: "alma_details_text",
                          })}
                        </ProductAlmaDetail>
                      </ProductAlmaPriceWrapper>
                    </MobileProductBottomPriceInfo>
                  )}
                {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                  <MobileProductBottomPriceInfo>
                    <ProductPriceWrapper align="flex-start">
                      <ProductPrice>
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                        <ProductPriceInformationSection />
                      </ProductPrice>
                      {/* <ProductDiscountPrice>
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${discountPrice}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                      </ProductDiscountPrice>
                      <ProductStrikePrice>
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                        <ProductPriceInformationSection />
                      </ProductStrikePrice> */}
                    </ProductPriceWrapper>
                  </MobileProductBottomPriceInfo>
                )}
                {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                  <MobileProductBottomPriceInfo>
                    <ProductKlarnaSection
                      price={Number(
                        selectedVariant
                          ? selectedVariant.price
                          : product.priceRangeV2.maxVariantPrice.amount
                      ).toFixed(0)}
                      page="lynefit"
                    />
                  </MobileProductBottomPriceInfo>
                )}
                <UserTypeWrapper>
                  {data.allDirectusLynefitTranslation.nodes[0]
                    .choose_gender_text && (
                    <ChooseGenderText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLynefitTranslation.nodes[0]
                            .choose_gender_text,
                      }}
                    />
                  )}
                  <ProductUserType
                    data={
                      data.allDirectusLynefitTranslation.nodes[0].gender.item
                    }
                    handleChange={handleChangeSelectOptions}
                    value={selectOptions["Gender"]}
                    error={selectOptions["Gender"] ? null : Errors.exist}
                    product={"Lyne FIT"}
                  />
                </UserTypeWrapper>

                <ProductColorBox
                  data={data.allDirectusLynefitTranslation.nodes[0].color.item}
                  handleChange={handleChangeSelectOptions}
                  disabled={!(selectOptions && selectOptions["Gender"])}
                  value={selectOptions["Color"]}
                  colors={colorsLyneFit}
                  colorsReverse={colorsReverseLyneFit}
                  formatText={CapitalizeFirstLetter}
                  error={!selectOptions["Color"] && Errors.exist}
                />
                {/*</ProductSummaryTop> */}

                <ProductSummaryBottom>
                  {data.allDirectusLynefitTranslation.nodes[0]
                    .choose_size_suggestion_text &&
                    selectOptions &&
                    selectOptions["Gender"] && (
                      <ChooseSizeSeggestionText
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLynefitTranslation.nodes[0]
                              .choose_size_suggestion_text,
                        }}
                      />
                    )}
                  <div className="size_drop_down">
                    {!(selectOptions && selectOptions["Gender"]) && (
                      <LayerDisabled />
                    )}
                    <div className="size_drop_down_child">
                      <Select
                        id="pa_taille_classique"
                        name="Size"
                        onChange={handleChangeSelectOptions}
                        value={selectOptions["Size"]}
                        error={!selectOptions["Size"] && Errors.exist}
                        pageName="lynefit"
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "my_percko_product_size_text",
                          })}
                        </option>
                        {selectOptions["Gender"] &&
                          data.allDirectusLynefitTranslation.nodes[0].size[
                            selectOptions["Gender"]
                          ].map(value => (
                            <option
                              value={value}
                              key={`Size-${value}`}
                              style={{
                                color:
                                  process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                                  selectOptions["Gender"] &&
                                  selectOptions["Color"] &&
                                  OutOfStockItems[
                                    process.env.GATSBY_CURRENCY_TYPE
                                  ]["lynefit"][selectOptions["Gender"]][
                                    selectOptions["Color"]
                                  ]?.includes(value)
                                    ? "#C7C6C5"
                                    : "#262626",
                              }}
                            >
                              {value}
                            </option>
                          ))}
                      </Select>
                      {/* <DropIconMobile>
                        <FaAngleDown size={16} />
                      </DropIconMobile>
                      <DropIcon>
                        <FaAngleDown size={16} />
                      </DropIcon> */}
                    </div>
                  </div>
                  {Errors && Errors.exist && (
                    <ErrorBox>
                      {Errors.message.map((item, index) => (
                        <span key={index.toString()}>{item}</span>
                      ))}
                    </ErrorBox>
                  )}
                  <SizeRulerBox>
                    <SizeRulerImage src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg" />
                    <GuideSizeDetail
                      onClick={() =>
                        selectOptions &&
                        selectOptions["Gender"] &&
                        setopenSizeGuideModal(!openSizeGuideModal)
                      }
                    >
                      {!(selectOptions && selectOptions["Gender"]) && (
                        <LayerDisabled />
                      )}
                      {
                        data.allDirectusLynefitTranslation.nodes[0]
                          .size_chart_text
                      }
                    </GuideSizeDetail>
                  </SizeRulerBox>
                  {(currentlyNotInStock ||
                    (process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                      selectOptions["Gender"] &&
                      selectOptions["Color"] &&
                      selectOptions["Size"] &&
                      OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                        "lynefit"
                      ][selectOptions["Gender"]][
                        selectOptions["Color"]
                      ]?.includes(selectOptions["Size"]))) && (
                    <ProductOutOfStockMessage
                      dangerouslySetInnerHTML={{
                        __html: intl
                          .formatMessage({
                            id: "lyne_out_of_stock_message",
                          })
                          .replace(
                            "/producturl/",
                            `${
                              process.env.GATSBY_ACTIVE_ENV != "general"
                                ? "/" + process.env.GATSBY_ACTIVE_ENV
                                : ""
                            }/shop/lynefit/?Gender=${
                              selectOptions["Gender"]
                            }&Color=BLACK MICRO-PERFORATED&Size=${
                              selectOptions["Size"]
                            }`
                          ),
                      }}
                    />
                  )}
                  {((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr") ||
                    process.env.GATSBY_CURRENCY_TYPE === "gbp") &&
                    selectOptions["Gender"] &&
                    selectOptions["Color"] &&
                    data.allDirectusLynefitTranslation.nodes[0]
                      .combo_section && (
                      <LyneComboSection
                        disabled={
                          currentlyNotInStock ||
                          (process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                            selectOptions["Gender"] &&
                            selectOptions["Color"] &&
                            selectOptions["Size"] &&
                            OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                              "lynefit"
                            ][selectOptions["Gender"]][
                              selectOptions["Color"]
                            ]?.includes(selectOptions["Size"]))
                        }
                      >
                        <LyneComboWrapper>
                          {data.allDirectusLynefitTranslation.nodes[0]
                            .combo_section.single_product_text && (
                            <LyneComboBox
                              onClick={() => handleProductType("Seul")}
                            >
                              {/* {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                                <LyneComboBoxTagWrapper>
                                  <LyneComboBoxTag lang={intl.locale}>
                                    Sale
                                  </LyneComboBoxTag>
                                </LyneComboBoxTagWrapper>
                              )} */}
                              <LyneComboBoxTitle
                                dangerouslySetInnerHTML={{
                                  __html:
                                    data.allDirectusLynefitTranslation.nodes[0]
                                      .combo_section.single_product_text,
                                }}
                              />
                              <LyneComboBottomSection>
                                <LyneComboLeftSection>
                                  {selectOptions["Gender"] == "Female" &&
                                  selectOptions["Color"] == "BLUE" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/8417685d-8585-4043-9822-538f2b9fda51.png" />
                                  ) : selectOptions["Gender"] == "Female" &&
                                    selectOptions["Color"] ==
                                      "BLACK MICRO-PERFORATED" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/0509d307-191b-47fc-bf92-8f714a97e39d.png" />
                                  ) : selectOptions["Gender"] == "Male" &&
                                    selectOptions["Color"] == "BLUE" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/249160a2-844a-4032-85bd-6949ee461966.png" />
                                  ) : (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/36987bf6-5da5-450e-84b4-06d5165c8abc.png" />
                                  )}
                                  <LyneProductRegularPrice>
                                    {`${
                                      process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }${Number(
                                      selectedVariant
                                        ? selectedVariant.price
                                        : product.priceRangeV2.maxVariantPrice
                                            .amount
                                    ).toFixed(0)}${
                                      process.env.GATSBY_CURRENCY_TYPE === "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }`}
                                  </LyneProductRegularPrice>
                                  {/* <LyneProductDiscountPrice>
                                    {`${
                                      process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }${lyneDiscountPrice()}${
                                      process.env.GATSBY_CURRENCY_TYPE === "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }`}
                                  </LyneProductDiscountPrice>
                                  {process.env.GATSBY_CURRENCY_TYPE ===
                                    "gbp" && (
                                    <LyneProductStrikePrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${Number(
                                        selectedVariant
                                          ? selectedVariant.price
                                          : product.priceRangeV2.maxVariantPrice
                                              .amount
                                      ).toFixed(0)}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductStrikePrice>
                                  )} */}
                                </LyneComboLeftSection>
                                <LyneCheckImage
                                  src={
                                    selectProductType === "Seul"
                                      ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                      : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                  }
                                />
                              </LyneComboBottomSection>
                            </LyneComboBox>
                          )}
                          {data.allDirectusLynefitTranslation.nodes[0]
                            .combo_section.offer_title &&
                            data.allDirectusLynefitTranslation.nodes[0]
                              .combo_section.offer_text && (
                              <LyneComboBox
                                border="0px"
                                onClick={() => handleProductType("Pack")}
                              >
                                <LyneComboBoxTagWrapper>
                                  {/* <LyneComboBoxTag
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data.allDirectusLynefitTranslation
                                          .nodes[0].combo_section.offer_title,
                                    }}
                                    lang={intl.locale}
                                  /> */}
                                  {intl.locale === "fr" ? (
                                    <LyneComboBoxTag lang={intl.locale}>
                                      Offre pack
                                      {/* <span>
                                        Derniers
                                        <br />
                                        jours
                                      </span> */}
                                      {/* <ClockImage src={CoqIcon} /> */}
                                    </LyneComboBoxTag>
                                  ) : (
                                    <LyneComboBoxTag lang={intl.locale}>
                                      Sale
                                    </LyneComboBoxTag>
                                  )}
                                  {/* <LyneComboInfoIcon
                                    data-tip={
                                      data.allDirectusLynefitTranslation
                                        .nodes[0].combo_tooltip
                                    }
                                    data-html={true}
                                    data-for="lyne_combo_info"
                                  >
                                    i
                                  </LyneComboInfoIcon>
                                  <ReactTooltip
                                    id="lyne_combo_info"
                                    html={true}
                                  /> */}
                                </LyneComboBoxTagWrapper>
                                <LyneComboBoxTitle
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLynefitTranslation
                                        .nodes[0].combo_section.offer_text,
                                  }}
                                />
                                <LyneComboBottomSection>
                                  <LyneComboLeftSection>
                                    {selectOptions["Gender"] == "Female" &&
                                    selectOptions["Color"] == "BLUE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/2642ef6e-d4b6-4850-8e13-b134ad7cc0d8.png" />
                                    ) : selectOptions["Gender"] == "Female" &&
                                      selectOptions["Color"] ==
                                        "BLACK MICRO-PERFORATED" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/2e86cc61-d34e-4013-a406-548cd136c044.png" />
                                    ) : selectOptions["Gender"] == "Male" &&
                                      selectOptions["Color"] == "BLUE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/97fc99ec-b4f5-4973-bf65-b87b3685bf40.png" />
                                    ) : (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/8c32b3b2-90c8-4676-9ab0-e900cbd19c48.png" />
                                    )}
                                    <LyneProductDiscountPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneGroupDiscountPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductDiscountPrice>
                                    <LyneProductStrikePrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneGroupPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductStrikePrice>
                                  </LyneComboLeftSection>
                                  <LyneCheckImage
                                    src={
                                      selectProductType === "Pack"
                                        ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                        : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                    }
                                  />
                                </LyneComboBottomSection>
                              </LyneComboBox>
                            )}
                        </LyneComboWrapper>
                        <LyneComboRightBox
                          dangerouslySetInnerHTML={{
                            __html:
                              data.allDirectusLynefitTranslation.nodes[0]
                                .combo_tooltip,
                          }}
                          lang={intl.locale}
                        />
                      </LyneComboSection>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" && (
                      <ProductBottomInfo>
                        {/* <DesktopProductBottomPriceInfo>
                          <ProductPriceWrapper>
                            <ProductPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice> */}
                        {/* <ProductDiscountPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${discountPrice}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductDiscountPrice>
                            <ProductStrikePrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductStrikePrice> */}
                        {/* <ProductPlusDetail
                              onClick={() => setopenModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "product_plus_details_text",
                              })}
                            </ProductPlusDetail> */}
                        {/* </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper>
                            <ProductAlmaPrice>
                              ou 3 x{" "}
                              <span>
                                {monthlyPrice(
                                  discountPrice
                                    ? 129
                                    : selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                )}
                              </span>
                            </ProductAlmaPrice>
                            <ProductAlmaDetail
                              onClick={() => setopenAlmaModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "alma_details_text",
                              })}
                            </ProductAlmaDetail>
                          </ProductAlmaPriceWrapper>
                        </DesktopProductBottomPriceInfo> */}
                        <ProductBottomCartButtonBox>
                          {/* <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            mobileDisplay={true}
                          /> */}
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={
                                process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                                selectOptions["Gender"] &&
                                selectOptions["Color"] &&
                                selectOptions["Size"] &&
                                OutOfStockItems[
                                  process.env.GATSBY_CURRENCY_TYPE
                                ]["lynefit"][selectOptions["Gender"]][
                                  selectOptions["Color"]
                                ]?.includes(selectOptions["Size"])
                                  ? true
                                  : currentlyNotInStock
                                  ? true
                                  : selectOptions["Gender"]
                                  ? false
                                  : true
                              }
                            >
                              {
                                data.allDirectusLynefitTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneFit",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductBottomCartButtonBox>
                      </ProductBottomInfo>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <ProductBottomInfo>
                      {/* <DesktopProductBottomPriceInfo>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductPrice> */}
                      {/* <ProductDiscountPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${discountPrice}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductDiscountPrice>
                          <ProductStrikePrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductStrikePrice> */}
                      {/* </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                          <ProductKlarnaSection
                            price={Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                          />
                        </ProductAlmaPriceWrapper>
                      </DesktopProductBottomPriceInfo> */}
                      <ProductBottomCartButtonBox>
                        {/* <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          mobileDisplay={true}
                        /> */}
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={
                              process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                              selectOptions["Gender"] &&
                              selectOptions["Color"] &&
                              selectOptions["Size"] &&
                              OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                                "lynefit"
                              ][selectOptions["Gender"]][
                                selectOptions["Color"]
                              ]?.includes(selectOptions["Size"])
                                ? true
                                : currentlyNotInStock
                                ? true
                                : selectOptions["Gender"]
                                ? false
                                : true
                            }
                          >
                            {
                              data.allDirectusLynefitTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon
                                className="lazyload"
                                data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                              />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneFit",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductBottomCartButtonBox>
                    </ProductBottomInfo>
                  )}
                  {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                    process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                    (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale != "fr")) && (
                    <ProductInfoBottom>
                      <DesktopPrice>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""}
                            {Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                            {process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""}
                          </ProductPrice>
                          <ProductPlusDetail
                            onClick={() => setopenModal(!openModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail>
                        </ProductPriceWrapper>
                      </DesktopPrice>
                      <ProductInfoBottomRight mobileDisplay={true}>
                        <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          mobileDisplay={true}
                        />
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={
                              process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
                              selectOptions["Gender"] &&
                              selectOptions["Color"] &&
                              selectOptions["Size"] &&
                              OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                                "lynefit"
                              ][selectOptions["Gender"]][
                                selectOptions["Color"]
                              ]?.includes(selectOptions["Size"])
                                ? true
                                : currentlyNotInStock
                                ? true
                                : selectOptions["Gender"]
                                ? false
                                : true
                            }
                          >
                            {
                              data.allDirectusLynefitTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneFit",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductInfoBottomRight>
                    </ProductInfoBottom>
                  )}
                  <ProductInfoBanner
                    data={
                      data.allDirectusLynefitTranslation.nodes[0]
                        .product_blue_banner_text
                    }
                  />
                </ProductSummaryBottom>
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusCommonTranslation.nodes[0]
            .new_testimonial_section && (
            <TestimonialSection
              data={
                data.allDirectusCommonTranslation.nodes[0]
                  .new_testimonial_section
              }
            />
          )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductBlueBanner
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .product_blue_banner_text
            }
            background="#9EDAED"
            color="#262626"
          />
        )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductResultSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_result_section
            }
          />
        )}

        {(intl.locale === "de" || intl.locale === "es") &&
          data.allDirectusCommonTranslation.nodes[0].medicale_text && (
            <MedicaleInfoSection
              data={data.allDirectusCommonTranslation.nodes[0].medicale_text}
            />
          )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductWhyChooseSection
            borderColor={titleBorderColor}
            titleBgColor="black"
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .product_why_choose_section
            }
          />
        )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductExpertSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_expert_section
            }
          />
        )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductSatisfiedClientSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .product_satisfied_client_section
            }
          />
        )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductVideoSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .product_video_testimonial
            }
          />
        )}

        {/* {(intl.locale === "de" || intl.locale === "es") && (
          <ProductReviewSection
            data={data.allDirectusReviewTranslation.nodes}
            id={"review_section"}
            page="Lyne FIT"
          />
        )} */}

        {(intl.locale === "de" || intl.locale === "es") &&
          data.allDirectusReviewTranslation.nodes && (
            <TrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              id={"review_section"}
              page="lynefit"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0].page_section_list && (
            <LyneupPageSectionList
              data={
                data.allDirectusLynefitTranslation.nodes[0].page_section_list
              }
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0].highlight_section && (
            <LyneupHighlightSection
              data={
                data.allDirectusLynefitTranslation.nodes[0].highlight_section
              }
              id="highlights"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0]
            .product_information_section && (
            <LyneupProductInformationSection
              data={
                data.allDirectusLynefitTranslation.nodes[0]
                  .product_information_section
              }
              id="product_information"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0].technology_section && (
            <LyneupTechnologySection
              data={
                data.allDirectusLynefitTranslation.nodes[0].technology_section
              }
              id="technology"
            />
          )}

        {/* {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0].medical_section && (
            <LyneupMedicalSection
              data={data.allDirectusLynefitTranslation.nodes[0].medical_section}
              id="medical_device"
            />
          )} */}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusNewHomePageTranslation.nodes[0].result_section && (
            <ResultHomeSection
              data={
                data.allDirectusNewHomePageTranslation.nodes[0].result_section
              }
              id="medical_device"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0]
            .how_to_use_lynefit_section && (
            <HowToUseSection
              data={
                data.allDirectusLynefitTranslation.nodes[0]
                  .how_to_use_lynefit_section
              }
              id="how_to_use_lynefit"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLynefitTranslation.nodes[0].review_section && (
            <LyneupReviewSection
              data={data.allDirectusLynefitTranslation.nodes[0].review_section}
              id="review"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusReviewTranslation.nodes && (
            <TrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              id={"review_section"}
              page="lyneup-test"
            />
          )}

        {intl.locale === "de" || intl.locale === "es" ? (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_faq_section
            }
            id="faq"
          />
        ) : process.env &&
          data.allDirectusLynefitTranslation.nodes[0].gbp_product_faq_section &&
          process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <LyneFitFaqSection
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .gbp_product_faq_section
            }
            id="faq"
          />
        ) : (
          <LyneFitFaqSection
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_faq_section
            }
            id="faq"
          />
        )}

        {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
          intl.locale === "fr" &&
          data.allDirectusLynefitTranslation.nodes[0].cross_section && (
            <CrossProductSection
              data={data.allDirectusLynefitTranslation.nodes[0].cross_section}
              price={data.allShopifyProduct.edges}
            />
          )}

        {((process.env.GATSBY_CURRENCY_TYPE != "eur" && intl.locale === "fr") ||
          intl.locale === "en") && (
          <LyneHomeCrossProductSection
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_cross_section
            }
            id="other_products"
            page="lyneup"
          />
        )}

        {data.allDirectusHomeTranslation.nodes[0].customer_servcie_text && (
          <CustomerService
            text={
              data.allDirectusHomeTranslation.nodes[0].customer_servcie_text
            }
          />
        )}
        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLynefitTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_images_section {
          default_image
          men {
            noir
            bleu
          }
          women {
            noir
            bleu
          }
        }
        product_material_info
        product_add_to_cart_button
        product_blue_banner_text
        product_result_section {
          section_title
          list {
            title
            mobile_title
            text
          }
        }
        product_why_choose_section {
          section_title
          mobile_section_title
          slider {
            image
            title
            text
          }
          text_list {
            image
            text
            mobile_text
          }
        }
        product_expert_section {
          section_title
          mobile_section_title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        product_satisfied_client_section {
          title
          mobile_title
          subtitle
          left_text {
            title
            text
          }
          right_text {
            title
            text
            link
            link_url
          }
        }
        product_video_testimonial {
          testimonials {
            title
            author
            thumb
            video
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        size {
          Male
          Female
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        language
        price_details
        my_custom_size_text
        one_size_system_text
        alma_popup_text
        size_chart_text
        male_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text
        choose_size_suggestion_text
        page_section_list {
          list {
            title
            id
          }
        }
        highlight_section {
          image
          title
          text
        }
        product_information_section {
          title
          list {
            image
            text
          }
        }
        technology_section {
          title
          text
          image
          mobile_image
          button_text
          button_url
        }
        medical_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        how_to_use_lynefit_section {
          title
          list {
            image
            text
            second_text
          }
        }
        review_section {
          title
          text
          review_list {
            image
            name
            designation
            company
            city
            text
          }
          media_title
          media_list {
            image
            text
          }
        }
        product_cross_section {
          title
          list {
            image
            title
            logo
            button_text
            button_url
          }
        }
        combo_section {
          single_product_text
          offer_title
          offer_text
        }
        combo_tooltip
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusReviewTranslation(
      filter: {
        review: { product: { eq: "lynefit" } }
        language: { eq: $lang }
      }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusNewHomePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        result_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        video_section_text {
          title
          mobile_title
          button_text
          button_url
          medical_image
          innovation_image
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        company_title
        company_subtitle
        medicale_text
        medicale_logo {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        months_text {
          month
        }
        group_add_to_cart_title
        group_add_to_cart_save_text
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "lynefit" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lynefit" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneFit))
